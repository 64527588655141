import React from "react"

import { PlaceholderProvider } from '../components/placeholder'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import slogan from "../images/hashtag-spurHerOn.svg"
import hsbc from "../images/HSBC_Logo-Strip_Bowl-Static.svg"

const Bowl = () => (
  <PlaceholderProvider value={`hsbc`}>
    <Layout>
      <SEO title="Bowl HSBC" />
      <div className="cardsContainer cardsContainer--bowl cardsContainer--hsbc">
          <Cards postCount={2} hero={false} />
          <img className="slogan slogan--spur-her-on" src={slogan} alt="#SpurHerOn" />
          <img className="logo-hsbc" src={hsbc} alt="HSBC" />
      </div>
    </Layout>    
  </PlaceholderProvider>
)

export default Bowl
